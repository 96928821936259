<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>
import mixinPage from "../../libs/mixinPage";
export default {
  mixins: [mixinPage],
  components: {},
  name: "x1",
  data() {
    return {
      pageName:this.$options.name,
      xtysData:[
        
        {
          icon:"icon1",
          t1:"会员营销功能",
          t2:"全新会员营销系统除了提供传统的线下会员卡办理、充值、消费功能外，还支持线上无卡式会员功能，关注公众号即成为会员。另外还提供多种营销方式提高企业针对会员的营销能力，与微信、会员系统联动。同时满足企业对营运营销、会员营销双重要求。",
          img:"img2",
        },
        {
          icon:"icon7",
          t1:"成本核算功能",
          t2:"中餐的成本核算，特别是贵价食材的核算与控制问题一直是头号难题，为了解决这个问题，我们的系统不断优化功能，由成本配方表、部门领用自动生成二级部门入库、销售自动减库存几个环节，不但实现了成本核算的功能，更加考虑了实施的可行性与难度。",
          img:"img9",
        },
        {
          icon:"icon5",
          t1:"智能厨控功能",
          t2:"提供从点单数据采集到砧板预处理分发、打荷备料、厨师制作到传菜出品几个工作岗位的智能化处理，取代了传统的出品小票打印方式，既节约了纸张成本，更合理地提高工作效率，为绩效计算及出品优化提供了数据分析支撑。",
          img:"img8",
        },
        {
          icon:"icon8",
          t1:"宴会管理功能",
          t2:"大型餐饮必须非常重视宴会管理功能，这是区别与轻餐的竞争点所在。我们的系统提供了针对宴会的一系列管理，包括订位、大菜单的录入与打印、提前统计通知相关部门备料等。",
          img:"img1",
        },
        {
          icon:"icon4",
          t1:"云报表查询",
          t2:"提供了公众号或小程序云报表查询两种方式，涵盖了老板及高管人员日常关心的所有报表及图表，让老板随时随地掌握运营数据。",
          img:"img4",
        },
        {
          icon:"icon5",
          t1:"老板语音查询",
          t2:"系统在原有老板云报表的基础上创新开发了语音ＡＩ查询功能，老板只需说出你想查询的数据（例如营业收入、预订状况），小程序马上展现出结果。",
          img:"img3",
        },
      ],
      gnmkData:[
        {
          bg:"bg1",
          icon:"icon1",
          t1:"咨客管理",
          t2:"实现预定、领位、修改预订、短信通知、客人信息查询等功能。",
        },
        {
          bg:"bg2",
          icon:"icon2",
          t1:"前台收银",
          t2:"实现预定、开台、点单、转台、经理签送、经理打折、会员打折、包房发送信息、挂帐还款、查看房台操作记录、打印帐单、买单结帐等。",
        },
        {
          bg:"bg3",
          icon:"icon3",
          t1:"楼面点单",
          t2:"实现点单、退单、经理签送与打折、查看消费及打印小票等功能，还可以辅助实现存取酒功能。",
        },
        {
          bg:"bg4",
          icon:"icon4",
          t1:"移动应用",
          t2:"移动应用包括了客人扫码点单与支付，管理人员及服务员的小程序应用、老板云报表查询等。",
        },
        {
          bg:"bg5",
          icon:"icon5",
          t1:"出品监控",
          t2:"主要负责出品部门的酒水出品确认、监控、库存查询、沽清等操作，还负责存取酒操作及库存酒水的领用登记操作，对会员的存酒进行过期或延期管理。",
        },
        {
          bg:"bg6",
          icon:"icon6",
          t1:"财务管理",
          t2:"查询会计收支平衡表、营业汇总表、酒水销量报表、订房业绩报表、点单提成报表、会员往来帐分析、重要操作记录报表。",
        },
        {
          bg:"bg7",
          icon:"icon7",
          t1:"库存管理",
          t2:"实现申购单、入库单、出库单、退库单、调拨单、报损单、直拨单、退货单的管理，实现仓库或部门的盘点功能。",
        },
        // {
        //   bg:"bg8",
        //   icon:"icon8",
        //   t1:"超市收银",
        //   t2:"超市收银可以独立运行，也可以配合前台进行房台挂单、取单等操作。主要是实现超市商品快速买单、挂单、取单、挂房帐、经理签送、经理打折、会员打折、会员取酒等功能。",
        // },
        {
          bg:"bg9",
          icon:"icon9",
          t1:"后台管理",
          t2:"实现系统运营的相关设置，包括酒水类别、酒水菜牌、套餐、房台类型、楼层区域、房费收费标准、提成设置、人员权限、赠送权限、折扣方案及付款方式等。",
        },
      ],
      khList:[
        { url:require('@/images/kh/1.jpg'), t1:"耀华饮食集团", t2:"旗下 18 家店",},
        { url:require('@/images/kh/2.jpg'), t1:"华苑大酒店", t2:"旗下 3 家店",},
        { url:require('@/images/kh/3.jpg'), t1:"六合家宴", t2:"旗下 3 间",},
        { url:require('@/images/kh/4.jpg'), t1:"潮濠酒家", t2:"",},
        { url:require('@/images/kh/5.jpg'), t1:"爱丁堡酒家", t2:"",},
        { url:require('@/images/kh/6.jpg'), t1:"红树林酒家", t2:"",},
        { url:require('@/images/kh/7.jpg'), t1:"好吉祥大酒楼", t2:"",},
        { url:require('@/images/kh/8.jpg'), t1:"鸿运达酒店", t2:"",},
        { url:require('@/images/kh/9.jpg'), t1:"同发大酒家", t2:"",},
      ],
      
    };
  },
  methods: {
    handleScroll() {
      let that = this;
      // 页面滚动距顶部距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      // console.log(scrollTop);
      this.scrollTop = scrollTop;
      that.xtysScroll();
    },
    
  },
  mounted() {
    
  },
  activated() {}
};
</script>



